.badge {
  &.text-bg-dark {
    &.badge-outline {
      background-color: transparent !important;
      border: 1px solid #212529;
      color: #212529 !important;
    }
  }
  &.badge-xl {
    padding: .8rem 1.5rem;
  }
}

.custom-badge-id {
  align-items: center;
  display: flex;
  background-color: rgba(var(--brand-color), 0.1) !important;
  border: 1px solid rgba(var(--brand-color), 1) !important;
  color: rgba(var(--brand-color), 1) !important;
  padding: .75rem !important;
  span {
    font-size: 16px;
    vertical-align: sub;
  }
}