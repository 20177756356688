@import './_cards.scss';
@import './_colors.scss';
@import './_utilities.scss';
@import './_text.scss';
@import './_buttons.scss';
@import './_forms.scss';
@import './_tables.scss';
@import './_tabs.scss';
@import './_badges.scss';
@import './_page.scss';
@import './_working.scss';

.modal-header {
  &.no-border {
    border-bottom: none !important;
  }
}

.modal-footer {
  &.no-border {
    border-top: none !important;
  }
}

.empty-list {
  text-align: center;
  padding: 5rem 0;
  p { 
    font-size: 24px;
    line-height: 32px;
    margin: 1rem auto;
    width: 60%;
  }
}

textarea {
  &.no-resize {
    resize: none;
  }
}

.input-group {
  i.empty-search {
    color: rgba(var(--brand-color), 1);
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    right: 48px;
    top: 5px;
    z-index: 10;
  }
}

//OVERRIDES
.service-finished-modal {
  height: 55vh !important;
  padding: 2rem 0 !important;
  width: 800px !important;
}
// // Scrollbar ui
// ::-webkit-scrollbar {
//   -webkit-appearance: none;
//   width: 7px;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 4px;
//   background-color: rgba(0, 0, 0, .5);
//   box-shadow: 0 0 1px rgba(255, 255, 255, .5);
// }