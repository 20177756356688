:root {

  //Brand Colors
  --brand-color: 80, 151, 96;
  --brand-color-hover: #509760;

  --dollink-green: #509760;
  --dollink-green--dark: #121A14;
  --dollink-orange: #F56E4A;


  --secondary-color: #f4c065;
  --secondary-color-hover: #f4c065;
  --brand-dark-color: #1d2424;
  --white-color: #fff;
  --black-color: #000;

  // Alert colors
  --success-color: #509760;
  --warning-color: #F8BE48;
  --danger-color: #EB5857;
  --info-color: #4F95FC;

  //DOLLINK COLORS
  --light-gray-text-color: #4E5155;
}

$primary: #509760;
$secondary: #f4c065;

