.page-content {
  
  &-title {
    margin-bottom: 2rem;
  }

  &-actions {
    align-items: center;
    background-color: #fafbfc;
    border-radius: 8px;
    display: flex;
    justify-content: flex-end;
    padding: 1rem .5rem;
    position: relative;
    transition: .2s opacity ease;
    width: 100%;
  }

  &-body {
    margin-top: 2rem;
  }
}