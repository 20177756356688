.btn {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  &.btn-primary {
    background-color: rgb(var(--brand-color));
    color: var(--white-color);
    &.btn-outline {
      background-color: transparent;
      border-color: rgb(var(--brand-color));
      color: rgb(var(--brand-color));
      &:hover {
        background-color: rgb(var(--brand-color));
        color: var(--white-color);
      }
    }
    &:hover {
      background-color: rgb(var(--brand-color));
      color: var(--white-color);
    }
  }
  &.btn-secondary {
    background-color: var(--secondary-color);
    color: var(--black-color);
    &.btn-outline {
      background-color: transparent;
      border-color: var(--secondary-color);
      color: var(--secondary-color);
      &:hover {
        background-color: var(--secondary-color-hover);
        color: var(--white-color);
      }
    }
    &:hover {
      background-color: var(--secondary-color-hover);
    }
  }
  &.btn-danger {
    &.btn-outline {
      background-color: transparent;
      border: 1px solid var(--danger-color);
      color: var(--danger-color);
      &:hover {
        background-color: var(--danger-color);
        color: var(--white-color);
      }
    }
  }
  &.btn-cancel {
    &.btn-outline {
      background-color: transparent;
      border: 1px solid var(--brand-dark-color);
      color: var(--brand-dark-color);
      &:hover {
        background-color: var(--brand-dark-color);
        color: var(--white-color);
      }
    }
  }
  &.btn-link {
    background-color: transparent !important;
    border: none !important;
    color: rgb(var(--brand-color))!important;
  }
  &:disabled {
    cursor: default;
    opacity: 0.65;
  }
  &:hover {
    text-decoration: none;
  }
}

.btn-link {
  text-decoration: none !important;
}

/**
  Button Sizing
**/

.btn-block {
  display: block;
  width: 100%;
}

.btn-sm {
  padding: 0.25 0.5rem;
  font-size: .875rem;
  border-radius: .25rem;
}