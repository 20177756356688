

.input-group.has-validation {
  &:has(span) {
    input {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}

.page-content-title {
  nav {
    ol {
      li {
        font-size: 24px;
        a {
          text-decoration: none;
        }
      }
    }
  }
}

// project modals 
.custom-padding-project {
  padding: 24px 32px;
}

.custom-padding-project-actions {
  padding: 12px 32px;
}

// tables without td border
.table-bordered {
  .no-border-right {
    border-right-width: 0;
  }
  .no-border-left {
    border-left-width: 0;
  }
}

a {
  color: rgba(var(--brand-color, .8));
  text-decoration: none;
  &:hover {
    color: rgba(var(--brand-color), 1);
    text-decoration: underline;
  }
}

//tracking status events offcanvas

.offcanvas.tracking-status-events {
  overflow-y: scroll;
  --bs-offcanvas-width: 40%;
  //width: 320px !important;
}

// tabs
// .nav-tabs.custom-tabs .nav-link {
//   --bs-nav-tabs-border-width: 2px;
//   border: 2px solid transparent;
// }

// .nav-tabs.custom-tabs .nav-link.active {
//   border-bottom: 2px solid var(--dollink-green);
// } 

// .nav-tabs.custom-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
//   border-top: transparent;
//   border-left: transparent;
//   border-right: transparent;
//   border-bottom: 2px solid var(--dollink-green);
//   transition: .2s border ease-in;
// }