.font-brand {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
}

.text-branded-font {
  font-family: 'Titillium Web', sans-serif;
}

.text-brand-color {
  color: rgb(var(--brand-color)) !important;
}

// Warning text colors

.text-warning {
  color: var(--warning-color);
}

.text-danger {
  color: var(--danger-color);
}

.text-info {
  color: var(--info-color);
}

.text-success {
  color: var(--success-color);
}

/* Text Sizing */

h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2.75rem;
}
h3 {
  font-size: 2.5rem;
}
h4 {
  font-size:  2.25rem;
}
h5 {
  font-size: 2rem;
}
h6 {
  font-size: 1.75rem;
}

/* Font Bolder */
.bolder {
  font-weight: bolder !important;
}

.title {
  color: #1D2424;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}

.display {
  color: #1D2424;
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
}

@media screen and (min-width: 678px) {
  .title {
    font-size: 48px;
    line-height: 52px;
  }
  .display {
    font-size: 64px;
    line-height: 72px;
  }
}