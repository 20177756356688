.card {
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid #DCE4E1;
  min-height: 1;
  width: 100%;

  ///Card container
  &-body {
    display: flex;
    padding: 1rem;
    justify-content: space-between;
  }

  &-block {
    width: 33%;
    div.block-title {
      p {
        color: var(--light-gray-text-color);
        font-size: 14px;
        letter-spacing: .5px;
        margin: 0;
        padding: 0;        
      }
    }
    div.block-description {
      p {
        color: var(--brand-dark-color);
        font-weight: 700;
        margin: 0;
        padding: 0;
      }
      ul {
        margin: 0;
        padding: 0;
        li {
          display: inline-block;
          padding: 4px 0;
          font-weight: 700;
        }
      }
      span {
        color: rgb(var(--brand-color));
        cursor: pointer;
        font-size: 14px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  ///Background colors
  &.background-white {
    background-color: #fff;
  }
}