.tabs {
  height: auto;
  &-header{
    display: flex;
    margin-bottom: 1rem;
    dollink-ui-tabs {
      width: 100%;
    }
  }
  &-content {
    padding: 2rem 1rem;
  }
}