/* You can add global styles to this file, and also import other style files */
@import './assets/ui/dollink.ui.scss';
@import './assets/ui/colors';
@import "~@ng-select/ng-select/themes/default.theme.css";

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 1,
  'wght' 600,
  'GRAD' 0,
  'opsz' 40
}

body {
  font-family: 'Inter', sans-serif;
  color: #1D2424;
  scroll-behavior: smooth;
}

ul {
  list-style: none;
  padding-left: 0;
}

a {
  text-decoration: none;
}

.dimensions-modal .modal-header {
  border-bottom: none;
}
.dimensions-modal .modal-footer {
  border-top: none;
}
.no-dropdown-toggle.dropdown-toggle::after {
  display:none;
}

.dropdown-menu {
  padding-top: 0 !important;
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #dc3545;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

ngb-offcanvas-panel.offcanvas.offcanvas-end.request-events-offcanvas {
  width: 500px !important;
}

ngb-offcanvas-panel.offcanvas.offcanvas-end.request-events-offcanvas {
  width: 500px !important;
  div.offcanvas-body {
    height: calc(100vh - 69px);
    position: relative;
    overflow-x: scroll;
  }
}

// overrides
@import 'node_modules/bootstrap/scss/bootstrap';
@import './styles/vendors.scss'