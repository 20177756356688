
/* margins */
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.50rem;
}
.mb-3 {
  margin-bottom: 0.74rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mb-xl {
  margin-bottom: 5rem;
}


/* rounded items */
.rounded-circle {
  border-radius: 100%;
}

// Cursors
.cursor-pointer {
  cursor: pointer;
}

// user avatar
.avatar {
  height: 40px;
  margin: 0;
  width: 40px;
  img {
    border-radius: 100%;
    border: 2px solid white; 
    object-fit: cover;
    outline: 2px solid var(--dollink-green);
    height: 100%;
    width: 100%;
  }

  &-xl {
    height: 60px;
    width: 60px;
  }
  &-simple {
    img {
      border: 2px solid transparent; 
    }
  }
}

// empty states list
.empty-state {
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 60vh;
  p {
    font-size: 22px;
    font-weight: 700;
    margin-top: 1rem;
    text-align: center;
  }
}