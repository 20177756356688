.table {
  border-color:#dee2e6;
  color: #212529;
  vertical-align: top;
  width: 100%;
  thead {
    vertical-align: bottom;
  }
  tr {
    display: table-row;
  }
  td {
    vertical-align: middle;
  }
  :not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    background-color: transparent ;
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px transparent;
  }
}